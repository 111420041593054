video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-container{
    background: url('Background2.png') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-container > h1{
    color: #fff;
    font-size: 100px;
    margin-top: -1vh;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn--linkedin {
    background-color: #0077b5;
    color: rgb(255, 255, 255);
    border: none;
    transition: all 0.3s ease-out;
}

.btn--linkedin:hover {
    background-color: #005582;
    color: white;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
}

@media screen and (max-width: 960px){
    .hero-container > h1{
        font-size: 70px;
        margin-top: -1vh;
    }    
}

@media screen and (max-width: 768px){
    .hero-container > h1{
        font-size: 50px;
        margin-top: -1vh;
    }
    
    .hero-container > p{
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}

video.led {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    max-height: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:flex;
    margin: auto;
}

div.polaroidvid {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    width:40%;
    margin: auto;
}

div.polaroid3 {
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-left: 4%
}

div.polaroid {
    text-indent: 0;
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-top: 2%;
}
  
div.container {
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
}

img.imgs{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:100%;
}

img.imgs2{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:100%;
}

div.polaroid2 {
    text-indent: 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    margin-top:10%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

div.polaroid3 {
    text-indent: 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    margin-top:4%;
    max-width: none;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

div.polaroid4 {
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-left: 4%
}

.leftdiv{
    font-size: 18px;
    width: 50%;
    height: 100%;
    float: left;
    padding-right: 2%;
}

.rightdiv{
    margin-top: 0%;
    margin-left: 50%;
    height: 100%;
}

.smallcard{
    display: block;
    flex-flow: column;
    padding-bottom: 2%;
    padding-left: 30%;
    padding-right: 30%;
    text-align: left;
    overflow:hidden;
    font-size: 18px;
    overflow-wrap: break-word;
    text-indent: 2.5em;
}

.smallcard2{
    display: flex;
    flex-flow: column;
    padding-bottom: 3%;
    padding-left: 18%;
    padding-right: 18%;
    text-align: center;
    overflow:hidden;
    font-size: 20px;
    overflow-wrap: break-word;
}

.ledwrapper{
    position: relative;
    margin: 50px 0 45px;
    display: flex;
}

@media (max-width: 1600px) {
    .smallcard {
        padding-left: 23%;
        padding-right: 23%;
        font-size: 16px; 
    }
    .leftdiv{
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .smallcard {
        padding-left: 8%;
        padding-right: 8%;
        font-size: 14px; 
    }
    .leftdiv{
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .smallcard {
        padding-left: 8%;
        padding-right: 8%;
        font-size: 12px; 
    }
    .leftdiv{
        font-size: 8px;
    }
}

h1{
    padding-top:1%;
    padding-bottom: 1%;
}

.pdf{
    margin-top:1%;
}

.qualificationsmod{
    margin-top:4.5%;
}

.btn--primary{
    background-color: #ffffff;
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--primary:hover{
    background-color: #d3d3d3;
    color: #242424;
    border: 1px solid var(--primary);
}

/* New Profile Picture Styling */
/* Profile Picture Container */
.profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border: 5px solid white; /* White outline */
    margin-bottom: 2vh; /* Adjust this to control space above the name */
    margin-top:-15vh;
}

@media screen and (max-width: 400px){
    .profile-picture-container{
        margin-top:-7vh;
    }
}

/* Profile Picture */
.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

