body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'PT Sans', sans-serif; */
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {

    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
  }

  .paragraph {
    font-size: 1vw;
    display: grid;
    flex-flow: column;
    padding-bottom: 50px;
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
  .paragraph2 {
    font-size: 20px;
    display: flex;
    flex-flow: column;
    padding-bottom: 50px;
    max-width: 1000px;
    align-items: center;
  }

  .paragraphSmall {
    font-size: 10px;
    display: flex;
    flex-flow: column;
    padding-bottom: 50px;
    max-width: 1000px;
  }



  .imgsSmall{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 50%;
  }

  .pdf{
    width: 75%;
    height: 75vh;
    margin: 0 auto;
    background-color: #777;
    display: block;
  }

  @media screen and (max-width: 960px){
      .pdf{
          width: 90%;
          height: 75vh;
      }    
  }

  @media screen and (max-width: 600px){
    .pdf{
        width: 100%;
        height: 75vh;
    }    

    .container{
      padding: 0 !important;
    }
}

  .a{
    display: inline-block;
    text-indent: 0;
  }

  ul.list{
    text-align: left;
    list-style-position: inside;
    padding-left: 5%;
  }

  h1.the_specs{
    font-size: larger;
  }
:root{
    --primary: #fff;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: #ffffff;
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--primary:hover{
    background-color: #d3d3d3;
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  /*max-width: 1500px;*/
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}


video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-container{
    background: url(/static/media/Background2.ca7a297c.png) center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-container > h1{
    color: #fff;
    font-size: 100px;
    margin-top: -1vh;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn--linkedin {
    background-color: #0077b5;
    color: rgb(255, 255, 255);
    border: none;
    transition: all 0.3s ease-out;
}

.btn--linkedin:hover {
    background-color: #005582;
    color: white;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
}

@media screen and (max-width: 960px){
    .hero-container > h1{
        font-size: 70px;
        margin-top: -1vh;
    }    
}

@media screen and (max-width: 768px){
    .hero-container > h1{
        font-size: 50px;
        margin-top: -1vh;
    }
    
    .hero-container > p{
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}

video.led {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    max-height: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:flex;
    margin: auto;
}

div.polaroidvid {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    width:40%;
    margin: auto;
}

div.polaroid3 {
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-left: 4%
}

div.polaroid {
    text-indent: 0;
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-top: 2%;
}
  
div.container {
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
}

img.imgs{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:100%;
}

img.imgs2{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:100%;
}

div.polaroid2 {
    text-indent: 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    margin-top:10%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

div.polaroid3 {
    text-indent: 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    margin-top:4%;
    max-width: none;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

div.polaroid4 {
    max-width: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 10px;
    margin: auto;
    margin-left: 4%
}

.leftdiv{
    font-size: 18px;
    width: 50%;
    height: 100%;
    float: left;
    padding-right: 2%;
}

.rightdiv{
    margin-top: 0%;
    margin-left: 50%;
    height: 100%;
}

.smallcard{
    display: block;
    flex-flow: column;
    padding-bottom: 2%;
    padding-left: 30%;
    padding-right: 30%;
    text-align: left;
    overflow:hidden;
    font-size: 18px;
    overflow-wrap: break-word;
    text-indent: 2.5em;
}

.smallcard2{
    display: flex;
    flex-flow: column;
    padding-bottom: 3%;
    padding-left: 18%;
    padding-right: 18%;
    text-align: center;
    overflow:hidden;
    font-size: 20px;
    overflow-wrap: break-word;
}

.ledwrapper{
    position: relative;
    margin: 50px 0 45px;
    display: flex;
}

@media (max-width: 1600px) {
    .smallcard {
        padding-left: 23%;
        padding-right: 23%;
        font-size: 16px; 
    }
    .leftdiv{
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .smallcard {
        padding-left: 8%;
        padding-right: 8%;
        font-size: 14px; 
    }
    .leftdiv{
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .smallcard {
        padding-left: 8%;
        padding-right: 8%;
        font-size: 12px; 
    }
    .leftdiv{
        font-size: 8px;
    }
}

h1{
    padding-top:1%;
    padding-bottom: 1%;
}

.pdf{
    margin-top:1%;
}

.qualificationsmod{
    margin-top:4.5%;
}

.btn--primary{
    background-color: #ffffff;
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--primary:hover{
    background-color: #d3d3d3;
    color: #242424;
    border: 1px solid var(--primary);
}

/* New Profile Picture Styling */
/* Profile Picture Container */
.profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border: 5px solid white; /* White outline */
    margin-bottom: 2vh; /* Adjust this to control space above the name */
    margin-top:-15vh;
}

@media screen and (max-width: 400px){
    .profile-picture-container{
        margin-top:-7vh;
    }
}

/* Profile Picture */
.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.cards {
    padding: 10px 20px;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: rgb(26, 23, 23);
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  margin-top: 30px;
  font-size: 2.5rem;
  color: #333;
}

.post {
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

h2 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 10px;
}

.post .date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 10px;
}

.post .date-container .start-date {
  font-style: italic;
}

.post .date-container .date-posted {
  font-style: italic;
}

.post p {
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
}

.post p:last-child {
  margin-bottom: 0;
}

/* Carousel Image Styling */
.carousel-image {
  width: 100%;
  height: 500px; /* Set a fixed height for the carousel */
  object-fit: cover; /* Ensure images maintain aspect ratio and cover the entire area */
}

.date {
  white-space: pre; /* Preserves tabs and white spaces */
  font-style: italic;
  margin: 0;
}
/* react-slick Custom Styling */

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #fff;
  border: none;
  font-size: 2rem;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Dots */
.slick-dots {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333; /* Inactive dot color */
}

.slick-dots li.slick-active button:before {
  color: #333; /* Active dot color */
}

.slick-dots li button:hover:before {
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .post {
    padding: 15px;
  }

  .carousel-image {
    height: 300px; /* Adjusted height for smaller screens */
  }

  .slick-prev,
  .slick-next {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
