@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'PT Sans', sans-serif; */
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {

    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
  }

  .paragraph {
    font-size: 1vw;
    display: grid;
    flex-flow: column;
    padding-bottom: 50px;
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
  .paragraph2 {
    font-size: 20px;
    display: flex;
    flex-flow: column;
    padding-bottom: 50px;
    max-width: 1000px;
    align-items: center;
  }

  .paragraphSmall {
    font-size: 10px;
    display: flex;
    flex-flow: column;
    padding-bottom: 50px;
    max-width: 1000px;
  }



  .imgsSmall{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 50%;
  }

  .pdf{
    width: 75%;
    height: 75vh;
    margin: 0 auto;
    background-color: #777;
    display: block;
  }

  @media screen and (max-width: 960px){
      .pdf{
          width: 90%;
          height: 75vh;
      }    
  }

  @media screen and (max-width: 600px){
    .pdf{
        width: 100%;
        height: 75vh;
    }    

    .container{
      padding: 0 !important;
    }
}

  .a{
    display: inline-block;
    text-indent: 0;
  }

  ul.list{
    text-align: left;
    list-style-position: inside;
    padding-left: 5%;
  }

  h1.the_specs{
    font-size: larger;
  }